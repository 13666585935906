import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import HeaderHome from './components/HeaderHome';


ReactDOM.render(
    <React.StrictMode>
      <App/> 
    </React.StrictMode>,
    document.getElementById('root')
  )